import React, {useState} from "react";
import {Button, message, Steps} from 'antd';
import VolunteersImage from "../../assets/images/volunteers.png";
import ButtonArrow from "../../assets/images/buttonArrow.png";
import ButtonArrowPrev from "../../assets/images/buttonArrow 1.png";
import styles from "./Volunteers.module.scss";
import VolunteerForm from "../../components/volunteer-form/VolunteerForm";
import {useTranslation} from "react-i18next";
import {t} from "i18next";


const steps = [
  {
    title: '', content: (
        <div className={styles.StepOne}>
          <h2>Bëhu Pjesë e Suksesit të Futbollit!</h2>
          <p>Programi i Vullnetarëve i Federatës së Futbollit të Kosovës (FFK) është një mundësi unike për të gjithë të
            rinjtë
            dhe të rejat që dëshirojnë të kontribuojnë në futboll dhe të përfitojnë përvoja të vlefshme për të ardhmen e
            tyre.
            Si organizatë jofitimprurëse që ka për qëllim zhvillimin e futbollit në Kosovë, FFK organizon programe të
            ndryshme
            në bashkëpunim me UEFA-n dhe FIFA-n, duke synuar masivizimin e futbollit dhe përfshirjen e sa më shumë të
            rinjve në aktivitetet tona. </p>
          <br/>
          <p>Përmes programit të vullnetarëve, ju keni mundësinë të kontribuoni në organizimin e ngjarjeve të ndryshme,
            të krijoni lidhje të reja sociale, të përmirësoni aftësitë tuaja profesionale dhe të jeni pjesë e ngjarjeve
            të FFK-së,
            që shpesh kanë karakter ndërkombëtar. Si vullnetar, ju do të angazhoheni jo vetëm në ditën e ngjarjeve,
            por edhe në takime dhe trajnime të ofruara nga stafi ynë. </p>
        </div>
    ),
  },
  {
    title: '', content: (
        <div className={styles.StepOne}>
          <h2>Programi i Vullnetarëve</h2>
          <p>Formulari i aplikimit do të përmbajë një datë fillimi dhe përfundimi të periudhës së aplikimit,
            si dhe një përshkrim të shkurtër të ngjarjes për të cilën po aplikoni.</p>
          <div>
            <VolunteerForm/>
          </div>
        </div>
    ),
  },
  {
    title: '', content: (
        <div className={styles.StepOne}>
          <h2>Faleminderit për Interesimin Tuaj!</h2>
          <p>Aplikimi juaj është dorëzuar me sukses.
            Ne ju falënderojmë për interesimin tuaj për t'u bërë pjesë e Programit të Vullnetarëve në FFK. </p>
          <br/>
          <p>Së shpejti do të shqyrtojmë aplikimin tuaj dhe, nëse pranoheni ose refuzoheni për programin,
            do të merrni automatikisht një email informues me statusin tuaj.</p>
        </div>
    )
  },
];

const Volunteers = () => {
  const {t} = useTranslation();
  const [current, setCurrent] = useState(0);
  const items = steps.map((item) => ({key: item.title, title: item.title}));

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
      <div className={styles.VolunteersContainer}>
        <div className={styles.VolunteersContentContainer}>
          <div style={{display: 'flex'}}>
            <img src={VolunteersImage} alt="Volunteers"/>
            <div className={styles.VolunteersStepsContainer}>
              <div>
                <Steps current={current} items={items} progressDot={true}
                       style={{width: '200px', marginBottom: '3rem', marginLeft: '-63px'}}/>
                <div>{steps[current].content}</div>
              </div>
              <div className={styles.Buttons}>
                {current > 0 && (
                    <Button className={styles.ButtonStepPrev} onClick={() => prev()}>
                      <img src={ButtonArrowPrev} alt="Previous Step"/>
                      Kthehu një hap para
                    </Button>
                )}
                {current < steps.length - 1 && (
                    <Button className={styles.ButtonStepNext} type="primary" onClick={() => next()}>
                      Vazhdoni tek forma e aplikimit
                      <img src={ButtonArrow} alt="Next Step"/>
                    </Button>
                )}
                {current === steps.length - 1 && (
                    <Button className={styles.ButtonStepNext} type="primary"
                            onClick={() => message.success('Aplikimi u krye me sukses!')}>
                      Përfundo
                    </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Volunteers;
