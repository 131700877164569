import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./containers/home/Home";
import styles from "./App.module.scss";
import Competition from "./containers/competitions/Competition";
import Intermediaries from "./containers/intermediaries/Intermediaries";
import Committees from "./containers/committees/Committees";
import NormativeActs from "./containers/normative-acts/NormativeActs";
import Committee from "./containers/committee/Committee";
import Presidenti from "./containers/presidenti/Presidenti";
import News from "./containers/news/News";
import NotFound from "./containers/not-found/NotFound";
import Administration from "./containers/administration/Administration";
import Volunteers from "./containers/volunteers/Volunteers";

const App = () => (
  <div className={styles.RoutesWrapper}>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/intermediary" element={<Intermediaries />} />
      <Route path="/committees" element={<Committees />} />
      <Route path="/committee" element={<Committee />} />
      <Route path="/presidenti" element={<Presidenti />} />
      <Route path="/sekretar-i-pergjithshem" element={<Presidenti />} />
      <Route path="/normative-acts" element={<NormativeActs />} />
      <Route path="/garat/:title" element={<Competition />} />
      <Route path="/lajmet" element={<News />} />
      <Route path="/administration" element={<Administration />} />
      <Route path="/volunteers" element={<Volunteers />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </div>
);

export default App;
