import React, { useMemo } from "react";
import styles from "./Competition.module.scss";
import { useParams } from "react-router-dom";

const Competition = () => {
  const { title } = useParams();

  const formattedTitle = useMemo(() => {
    return title?.replace(/-/g, " ").toUpperCase();
  }, []);

  return (
    <div className={styles.MainWrapper}>
      <h1>{formattedTitle}</h1>
    </div>
  );
};

export default Competition;
