import React, { useCallback, useEffect, useRef, useState } from "react";
import FfkLogo from "../../assets/images/ffk-logo.png";
import styles from "./NavigationBar.module.scss";
import {
  DownOutlined,
  MenuOutlined,
  SearchOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import i18n from "../../i18n";
import useFetch from "../../hooks/useFetch";
import { CategoriesResponse } from "../../types/types";
import { Link } from "react-router-dom";

const NavigationBar = () => {
  const { t } = useTranslation();
  const { data, loading, error } = useFetch<CategoriesResponse>(
    "/categories/top-level",
  );
  const menuRef = useRef<HTMLDivElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openCategory, setOpenCategory] = useState<string | null>(null);

  const handleMenuClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsMenuOpen(true);
  }, []);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target as HTMLElement)
    ) {
      setIsMenuOpen(false);
      setOpenCategory(null);
    }
  }, []);

  const handleOpenCategory = useCallback((categoryName: string) => {
    setOpenCategory((prevCategory) =>
      prevCategory === categoryName ? null : categoryName,
    );
  }, []);

  const handleLanguageChange = useCallback(
    (language: string) => {
      i18n.changeLanguage(language).finally();
    },
    [i18n],
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.NavigationBar}>
      <Link className={styles.LogoImage} to="/">
        <img src={FfkLogo} alt="ffk-logo" />
      </Link>
      <div className={styles.NavigationBarAlignment}>
        <div className={styles.NavigationBarM}>
          <div className={styles.NavigationBarMain}>
            {/* Navigation Section */}
            <div className={styles.NavigationBarItems}>
              <Link to="#">{t("app.navigation.biletaria", "Biletaria")}</Link>
              <Link to="#">{t("app.navigation.ffkshop", "FFK Shop")}</Link>
            </div>
            {/* Language and Search Section */}
            <div className={styles.NavigationBarLanguages}>
              <span
                className={classNames({
                  [styles.CurrentLanguage]: i18n.language === "sq",
                })}
                onClick={() => handleLanguageChange("sq")}
              >
                ALB
              </span>{" "}
              |{" "}
              <span
                className={classNames({
                  [styles.CurrentLanguage]: i18n.language === "en",
                })}
                onClick={() => handleLanguageChange("en")}
              >
                ENG
              </span>
            </div>
          </div>
          <div className={styles.NavigationBarSearch}>
            <SearchOutlined />
          </div>
        </div>
        {/*Menu */}
        <div
          ref={menuRef}
          className={classNames(styles.NavigationBarMenu, {
            [styles.NavigationBarMenuOpen]: isMenuOpen,
          })}
          onClick={(e) => handleMenuClick(e)}
        >
          {!isMenuOpen ? (
            <>
              <MenuOutlined style={{ fontSize: "15px" }} />
              <p>{t("app.navigation.menu", "Menu")} </p>
            </>
          ) : (
            <div className={styles.NavigationLinksMenu}>
              {data?.map((item) => {
                return (
                  <h4 onClick={() => handleOpenCategory(item.name)}>
                    {item.name}
                    {openCategory === item.name ? (
                      <UpOutlined />
                    ) : (
                      <DownOutlined />
                    )}
                  </h4>
                );
              })}
            </div>
          )}
        </div>
        {openCategory !== null && (
          <div className={styles.NavigationLinksMenuItem}>
            {data
              ?.find((item) => item.name === openCategory)
              ?.subCategories.map((subCat) => (
                <div className={styles.NavigationLinksMenuItemSubCat}>
                  <h4>{subCat.name}</h4>
                  {subCat.subCategories.map((cat) => (
                    <h5>{cat.name}</h5>
                  ))}
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default NavigationBar;
