import React, {useRef} from "react";
import MainNewsPhoto from "../../assets/images/main-news.jpeg";
import { Badge, Button } from "antd";
import styles from "./MainNews.module.scss";
import { Carousel } from 'antd';
import {CarouselRef} from "antd/es/carousel";

const newsItems = [
  {
    title: "Presidenti Ademi dhe kryetari i Komunës së Fushë Kosovës diskutojnë për projektet infrastrukturore të futbollit",
    date: "September 2, 2024",
    content: "27 gusht, 2024 – Një delegacion i Federatës së Futbollit të Kosovës i kryesuar nga presidenti Agim Ademi, vizitoi sot Komunën e Fushë Kosovës. Gjatë kësaj vizite, presidenti Ademi me bashkëpunëtorë u takuan me kryetarin e kësaj komune, Burim Berisha, për të diskutuar rreth riorganizimit",
    image: MainNewsPhoto
  },
  {
    title: "Lorem Ipsum",
    date: "September 2, 2024",
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s...",
    image: MainNewsPhoto
  }
];

const MainNews = () => {
  const carouselRef = useRef<CarouselRef>(null);

  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };
  return (
      <>
        <Carousel
            autoplay
            effect="fade"
            className={styles.Carousel}
            dots={false}
            ref={carouselRef}>
          {newsItems.map((item, index) => (
              <div key={index}>
                <div className={styles.MainNewsContainer}>
                  <img src={item.image} alt="Titulli i lajmit"/>
                  <div>
                   <div className={styles.Badge}><Badge count="KRYESORE"/> <span>{item.date}</span></div>
                    <h1>{item.title}</h1>
                    <p>{item.content}</p>
                    <Button className={styles.ReadFullArticle}>Read full article</Button>
                  </div>
                </div>
              </div>
          ))}
        </Carousel>
        <div className={styles.Arrows}>
          <button className={styles.PrevArrow} onClick={handlePrev}>
            ‹
          </button>
          <button className={styles.NextArrow} onClick={handleNext}>
            ›
          </button>
        </div>
      </>
  );
}

export default MainNews;
