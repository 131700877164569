import React from "react";
import { Card } from "antd";
import styles from "./NewsCard.module.scss";
import NewsPhotoTest from "../../../assets/images/news-photo-test.png";
import { useTranslation } from "react-i18next";

interface NewsCardProps {
  teamType: 'men' | 'women'; // Define the teamType prop as either 'men' or 'women'
}

const NewsCard: React.FC<NewsCardProps> = ({ teamType }) => {
  const { t } = useTranslation();

  // Determine the badge text based on the teamType prop
  const badgeText =
      teamType === 'men'
          ? t("app.menNationalTeam", "Ekipi Kombëtar i Meshkujve")
          : t("app.womenNationalTeam", "Ekipi Kombëtar i Femrave");

  return (
      <Card
          className={styles.CardSizes}
          hoverable
          cover={
            <div className={styles.ImageContainer}>
              <img alt="news title" src={NewsPhotoTest} className={styles.NewsImage} />
              <div className={styles.Badge}>{badgeText}</div>
            </div>
          }
      >
        <div className={styles.CardContent}>
          {/* titulli i lajmit */}
          <h3>Fillon kampi dyditor i Kombëtares U17.</h3>
          {/* data e lajmrit */}
          <p>Jun 29, 2024</p>
        </div>
      </Card>
  );
};

export default NewsCard;
