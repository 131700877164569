import React from 'react';
import styles from './Partners.module.scss';
import Teb from "../../assets/images/Teb.png";
import ArtMotion from "../../assets/images/artMotion.png";
import Errea from "../../assets/images/errea-logo (1).png";
import Raiffaisen from "../../assets/images/Raiffaisen.png";
import Sigal from "../../assets/images/Sigal.png";
import Mokne from "../../assets/images/Mokne.png";
import Emerald from "../../assets/images/emerald-logo (1).png";
import AlTrade from "../../assets/images/alTrade.png";
import AlbiMall from "../../assets/images/albiMall.png";
import KepBank from "../../assets/images/kep-logo.svg";
const Partners = () => {
  return (
      <div className={styles.PartnersContainer}>
        <div className={styles.Marquee}>
          <div className={styles.MarqueeContent}>
            <img
                src={ArtMotion}
                alt="Art Motion"
                onClick={() => window.open('https://artmotion.net/', '_blank')}
                style={{cursor: 'pointer'}}
            />
            <img
                src={AlbiMall}
                alt="Albi Mall"
                onClick={() => window.open('https://www.facebook.com/albimall/', '_blank')}
                style={{cursor: 'pointer'}}
            />
            <img
                src={AlTrade}
                alt="Al Trade"
                onClick={() => window.open('https://altradecenter.com/', '_blank')}
                style={{cursor: 'pointer'}}
            />
            <img
                src={Mokne}
                alt="Mokne"
                onClick={() => window.open('https://mokne.com/', '_blank')}
                style={{cursor: 'pointer'}}
            />
            <img
                src={Emerald}
                alt="Emerald"
                onClick={() => window.open('https://emeraldhotel.info/', '_blank')}
                style={{cursor: 'pointer'}}
            />
            <img
                src={Errea}
                alt="Errea"
                onClick={() => window.open('https://www.errea.com/', '_blank')}
                style={{cursor: 'pointer'}}
            />
            <img
                src={Sigal}
                alt="Sigal"
                onClick={() => window.open('https://www.facebook.com/SigalKosova', '_blank')}
                style={{cursor: 'pointer'}}
            />
            <img
                src={Raiffaisen}
                alt="Raiffaisen Bank"
                onClick={() => window.open('https://www.raiffeisen-kosovo.com/sq/individe.html', '_blank')}
                style={{cursor: 'pointer'}}
            />
            <img
                src={Teb}
                alt="Teb"
                onClick={() => window.open('https://teb-kos.com/', '_blank')}
                style={{cursor: 'pointer'}}
            />
            <img
                src={KepBank}
                alt="Kep"
                onClick={() => window.open('https://keptrust.org/', '_blank')}
                style={{cursor: 'pointer'}}
            />
            {/* Duplicate images for smooth infinite scroll */}
            <img
                src={ArtMotion}
                alt="Art Motion"
                onClick={() => window.open('https://artmotion.net/', '_blank')}
                style={{cursor: 'pointer'}}
            />
            <img
                src={AlbiMall}
                alt="Albi Mall"
                onClick={() => window.open('https://www.facebook.com/albimall/', '_blank')}
                style={{cursor: 'pointer'}}
            />
            <img
                src={AlTrade}
                alt="Al Trade"
                onClick={() => window.open('https://altradecenter.com/', '_blank')}
                style={{cursor: 'pointer'}}
            />
            <img
                src={Mokne}
                alt="Mokne"
                onClick={() => window.open('https://mokne.com/', '_blank')}
                style={{cursor: 'pointer'}}
            />
            <img
                src={Emerald}
                alt="Emerald"
                onClick={() => window.open('https://emeraldhotel.info/', '_blank')}
                style={{cursor: 'pointer'}}
            />
            <img
                src={Errea}
                alt="Errea"
                onClick={() => window.open('https://www.errea.com/', '_blank')}
                style={{cursor: 'pointer'}}
            />
            <img
                src={Sigal}
                alt="Sigal"
                onClick={() => window.open('https://www.facebook.com/SigalKosova', '_blank')}
                style={{cursor: 'pointer'}}
            />
            <img
                src={Raiffaisen}
                alt="Raiffaisen Bank"
                onClick={() => window.open('https://www.raiffeisen-kosovo.com/sq/individe.html', '_blank')}
                style={{cursor: 'pointer'}}
            />
            <img
                src={Teb}
                alt="Teb"
                onClick={() => window.open('https://teb-kos.com/', '_blank')}
                style={{cursor: 'pointer'}}
            />
            <img
                src={KepBank}
                alt="Kep"
                onClick={() => window.open('https://keptrust.org/', '_blank')}
                style={{cursor: 'pointer'}}
            />
          </div>
        </div>
      </div>
  );
};

export default Partners;
