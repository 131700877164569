import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "antd";
import styles from "./News.module.scss";
import NewsCard from "../../components/_shared/news-card/NewsCard";
import Kosova from "../../assets/images/Kosova.jpg";

type TeamType = "men" | "women";

// Function to generate mock news data
const generateNewsData = (count: number): { teamType: TeamType }[] => {
  return Array.from({ length: count }, (_, index) => ({
    teamType: index % 2 === 0 ? "men" : "women",
  })); //funksion vec hajt
};

//mock data
const allNews = generateNewsData(20);

const News = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6;

  const paginatedNews = allNews.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
      <div className={styles.NewsContainer}>
        <h2>{t("app.news.news", "Informacionet me te reja")}</h2>

        <div className={styles.News}>
          <img src={Kosova} alt="news content"/>
          <div className={styles.MainNews}>
            <h1>
              Foda publikon listën e futbollistëve dardanë për dy ndeshjet e
              shtatorit
            </h1>
          </div>
        </div>
        <h1>{t("app.news.main", "Kryesore")}</h1>
        <div className={styles.AllNews}>
          {paginatedNews.map((newsItem, index) => (
              <NewsCard key={index} teamType={newsItem.teamType}/>
          ))}
        </div>
        <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={allNews.length}
            onChange={handlePageChange}
            style={{display: 'flex', marginTop: '10rem', justifyContent: 'center'}}
        />
      </div>
  );
};

export default News;
