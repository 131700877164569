import React, { useState } from 'react';
import { Form, Input, Select } from 'antd';
import { useTranslation } from "react-i18next";
import styles from "./VolunteerForm.module.scss";

const { Option } = Select;

const VolunteerForm: React.FC = () => {
  const { t } = useTranslation();
  const [nameError, setNameError] = useState("");
  const [cityError, setCityError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [idError, setIdError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [ageError, setAgeError] = useState("");

  const validateName = (name: string) => {
    const nameRegex = /^[A-Za-z]+ [A-Za-z]+$/;
    if (!name.trim()) {
      setNameError(t("app.validations.required", "Required"));
    } else if (!nameRegex.test(name)) {
      setNameError(t("app.volunteers.validation.nameFormat", "Duhet të jetë vetëm me shkronja dhe të përmbajë dy fjalë, p.sh. Filan Fisteku"));
    } else {
      setNameError("");
    }
  };

  const validateCity = (city: string) => {
    const cityRegex = /^[A-Za-z\s]+$/;
    if (!city.trim()) {
      setCityError(t("app.validations.required", "Required"));
    } else if (!cityRegex.test(city)) {
      setCityError(t("app.volunteers.validation.cityFormat", "Qyteti duhet të përmbajë vetëm shkronja"));
    } else {
      setCityError("");
    }
  };

  const validateGender = (gender: string) => {
    if (!gender.trim()) {
      setGenderError(t("app.validations.required", "Required"));
    } else {
      setGenderError("");
    }
  };

  const validateIdNumber = (id: string) => {
    const idRegex = /^\d{10}$/;
    if (!id.trim()) {
      setIdError(t("app.validations.required", "Required"));
    } else if (!idRegex.test(id)) {
      setIdError(t("app.volunteers.validation.idFormat", "Numri personal duhet të përmbajë 10 numra"));
    } else {
      setIdError("");
    }
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!email.trim()) {
      setEmailError(t("app.validations.required", "Required"));
    } else if (!emailRegex.test(email)) {
      setEmailError(t("app.volunteers.validation.emailFormat", "Formati i pavlefshëm i email-it."));
    } else {
      setEmailError("");
    }
  };

  const validateAge = (age: string) => {
    const ageNumber = parseInt(age);
    if (!age.trim()) {
      setAgeError(t("app.validations.required", "Required"));
    } else if (isNaN(ageNumber) || ageNumber < 18) {
      setAgeError(t("app.volunteers.validation.ageRestriction", "Mosha duhet të jetë mbi 18 vjeç"));
    } else {
      setAgeError("");
    }
  };

  return (
      <div className={styles.VolunteerFormContainer}>
        <Form>
          <div className={styles.VolunteerFormAlignment}>
            <div>
              <p>{t("app.volunteers.name&surname", "Emri & Mbiemri")}</p>
              <Form.Item validateStatus={nameError ? "error" : ""} help={nameError}>
                <Input
                    placeholder={t("app.volunteers.name&surname", "Emri & Mbiemri")}
                    onBlur={(e) => validateName(e.target.value)}
                />
              </Form.Item>
            </div>
            <div>
              <p>{t("app.volunteers.city", "Qyteti")}</p>
              <Form.Item validateStatus={cityError ? "error" : ""} help={cityError}>
                <Input
                    placeholder={t("app.volunteers.city", "Qyteti")}
                    onBlur={(e) => validateCity(e.target.value)}
                />
              </Form.Item>
            </div>
          </div>
          <div className={styles.VolunteerFormAlignment}>
            <div>
              <Form.Item validateStatus={genderError ? "error" : ""} help={genderError}>
                <p>{t("app.volunteers.gender", "Gjinia")}</p>
                <Select
                    placeholder={t("app.volunteers.gender", "Gjinia")}
                    onChange={(value) => validateGender(value as string)}
                >
                  <Option value="femer">Femër</Option>
                  <Option value="mashkull">Mashkull</Option>
                  <Option value="tjeter">Tjetër</Option>
                </Select>
              </Form.Item>

            </div>
            <div>
              <p>{t("app.volunteers.idNumber", "Numri personal")}</p>
              <Form.Item validateStatus={idError ? "error" : ""} help={idError}>
                <Input
                    placeholder={t("app.volunteers.idNumber", "Numri personal")}
                    onBlur={(e) => validateIdNumber(e.target.value)}
                />
              </Form.Item>
            </div>
          </div>
          <div className={styles.VolunteerFormAlignment}>
            <div>
              <p>{t("app.volunteers.email", "Email")}</p>
              <Form.Item validateStatus={emailError ? "error" : ""} help={emailError}>
                <Input
                    placeholder={t("app.volunteers.email", "Email")}
                    onBlur={(e) => validateEmail(e.target.value)}
                />
              </Form.Item>
            </div>
            <div>
              <p>{t("app.volunteers.age", "Age")}</p>
              <Form.Item validateStatus={ageError ? "error" : ""} help={ageError}>
                <Input
                    placeholder={t("app.volunteers.agePlaceholder", "Mosha minimale 18 vjeç")}
                    onBlur={(e) => validateAge(e.target.value)}
                />
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
  );
};

export default () => <VolunteerForm />;
